import { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import CartPreview from '@/components/Sitewide/Navbar/CartPreview'
import SearchBox from '@/components/Sitewide/Navbar/SearchBox'
import useOutsideClickHandler from '@/hooks/useOutsideClickHandler'
import {
  CloseIcon, HamburgerIcon, ZGallerieLocationsIcon, ZGallerieLogo,
} from '@/components/Sitewide/Navbar/icons'
import { useWindowScroll } from '@/hooks/useWindow'
import ZGoldBalance from '@/components/Sitewide/Navbar/ZGoldBalance'
import UserLogin from '@/components/Sitewide/Navbar/UserLogin'
import { useLoyalty } from '../NavigationContext'
import styles from './index.module.scss'
import BurgerMenu from './BurgerMenu'
import GrayBackground from './GrayBackground'

const TopNavigationMobile = ({
  meganav, pageType, productAdded, bannerVersion,
}) => {
  const [isOpenedBurgerMenu, setIsOpenedBurgerMenu] = useState(false)
  const onClickBurgerIcon = () => setIsOpenedBurgerMenu((isOpened) => !isOpened)
  const closeBurgerMenu = () => setIsOpenedBurgerMenu(false)

  const [isOpenedCartPreview, setIsOpenedCartPreview] = useState(false)
  const onCartPreviewClick = (isOpenedPreview) => setIsOpenedCartPreview(isOpenedPreview)

  const burgerMenuRef = useRef(null)
  useOutsideClickHandler(burgerMenuRef, () => closeBurgerMenu())

  const { y } = useWindowScroll()

  const [headerHeight, setHeaderHeight] = useState(0)

  useEffect(() => {
    setHeaderHeight(burgerMenuRef.current?.clientHeight)
  }, [burgerMenuRef, setHeaderHeight])

  const { isLoyaltyEnabled } = useLoyalty()

  useEffect(() => {
    setIsOpenedCartPreview((previousValue) => (!previousValue
      ? productAdded : previousValue))
  }, [productAdded, setIsOpenedCartPreview])

  return (
    <>
      <div
        ref={burgerMenuRef}
        className={clsx(
          styles.root,
          Number(bannerVersion) === 1 && styles.bannerV1Root,
          isOpenedBurgerMenu && styles.openedBurgerMenu,
        )}
      >
        {(isOpenedCartPreview || productAdded) && (
          <GrayBackground id="MobileNavBarisOpenedCartPreviewBg" onClose={() => setIsOpenedCartPreview(false)} />
        )}
        <div className={styles.navigationBar}>
          <div className={styles.leftNavigation}>
            <div className={styles.menuButton}>
              <button type="button" onClick={onClickBurgerIcon} className={styles.burgerMenuIcon}>
                {!isOpenedBurgerMenu ? (
                  <img
                    className="ssr-m-auto"
                    alt="Open burger menu"
                    src={HamburgerIcon}
                  />
                ) : (
                  <img
                    className="ssr-m-auto"
                    alt="Close burger menu"
                    src={CloseIcon}
                  />
                )}
              </button>
            </div>
            <Link href="/" prefetch={false}>
              <img
                alt="logo"
                className={styles.logo}
                src={ZGallerieLogo}
              />
            </Link>
          </div>
          <div className={styles.rightNavigation}>
            <div className={styles.storeLocations}>
              <Link href="/store-locations" prefetch={false}>
                <img
                  alt="locations"
                  src={ZGallerieLocationsIcon}
                />
              </Link>
            </div>
            <UserLogin />
            {isLoyaltyEnabled && <ZGoldBalance />}
            <div>
              <CartPreview
                styles={{
                  quantity: styles.cartButton,
                  preview: {
                    table: styles.cartPreview,
                    previewCartAction: styles.cartPreview,
                  },
                }}
                onClick={onCartPreviewClick}
                pageType={pageType}
                setPreviewVisible={setIsOpenedCartPreview}
                previewVisible={isOpenedCartPreview}
              />
            </div>
          </div>
        </div>
        {!isOpenedBurgerMenu && y < headerHeight && (
          <div className={styles.searchBar}>
            <div id="quickSearch" aria-hidden="true" tabIndex={-1}>
              <div id="search-app-container" className="container">
                <div id="quick_search_placeholder">
                  <SearchBox />
                </div>
              </div>
            </div>
          </div>
        )}
        {isOpenedBurgerMenu && (
          <BurgerMenu
            meganav={meganav}
            pageType={pageType}
            onOpenLink={closeBurgerMenu}
          />
        )}
      </div>
      {isOpenedBurgerMenu && <GrayBackground id="MobileNavBarisOpenedBurgerMenuBg" onClose={closeBurgerMenu} />}
    </>
  )
}

export default TopNavigationMobile
