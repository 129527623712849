import { useEffect } from 'react'

export const useOutsideClickHandler = (ref: React.RefObject<HTMLElement>, onClick: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event : MouseEvent) => {
      if (ref.current
        && event.target instanceof HTMLElement
        && !ref.current.contains(event.target)
      ) {
        onClick()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClick])
}

export default useOutsideClickHandler
