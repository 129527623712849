import SearchBox from '@/components/Sitewide/Navbar/SearchBox'
import Meganav from '@/components/Sitewide/Navbar/Meganav'
import styles from './index.module.scss'

const BurgerMenu = ({ meganav, pageType, onOpenLink }) => (
  <div className={styles.burgerMenu}>
    <div className={styles.searchBar}>
      <div id="quickSearch" aria-hidden="true" tabIndex={-1}>
        <div id="search-app-container">
          <div id="quick_search_placeholder">
            <SearchBox />
          </div>
        </div>
      </div>
    </div>
    <Meganav
      meganav={meganav}
      pageType={pageType}
      className={styles.menu}
      onOpenLink={onOpenLink}
    />
  </div>
)

export default BurgerMenu
